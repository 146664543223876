<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['About Us']" />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <card>
          <h3 class="title">
            About SEUS
          </h3>
          <h5 class="description">
            SEUS Consult is a consulting firm registered in Zambia and offer comprehensive, high quality and integrated
            development and environmental management services. SEUS Consult has targeted to be a market-leading
            consultancy providing urban development solutions in water, sanitation, environment sustainability, health
            and
            safety solutions.
          </h5>
          <h5 class="description">
            We manage all projects from our local offices, while maintaining close ties with Government agencies,
            proponents and other stakeholders throughout the extensive sub regional network of associates and
            researcher’s. The approach affords us have a virtual permanent presence in region, thereby maintaining the
            standards expected of international regulatory authorities and funding agencies.
          </h5>
          <h5 class="description">
            Our most important resource - the staff, have professional development and environmental experience
            extending
            throughout sub-Saharan Africa, and include qualified environmental and development scientists, engineers and
            project managers with a wealth of experience and complementary multi-disciplinary skills covering Water and
            Sanitation, Project Management and Capacity building.
          </h5> <h5 class="description">
            Through our network of associates and affiliated companies also draw together expertise in a wide range of
            additional disciplines such as engineering, hydrogeology, social and Mathematical modeling, GIS and Remote
            sensing, and analytical chemistry etc. to provide a comprehensive suite of environmental and development
            services. Thus, we are able to combine to build a skill-set that includes local and international expertise
            and knowledge with global reach and experience.
          </h5><h5 class="description">
            In a resource-constrained, low carbon economy, it is our aim to lead debate, influence policy and shape
            development in Environmental Management systems thereby achieving outstanding client success Our major
            services prove invaluable to our valued clients implementing various projects related to their particular
            need. These include:
          </h5>

          <ul class="description2">
            <li>Research & Baseline Studies</li>
            <li>Environmental and Renewable resources advisory services</li>
            <li>Environmental & Social Impact Assessment(ESIAs)</li>
            <li>Environmental Auditing & Monitoring</li>
            <li>Water, Sanitation & Resources Management</li>
            <li>Design & Development of Municipal Integrated Waste Management systems</li>
            <li>Remediation of Contaminated sites, Industrial clean-ups and rehabilitation</li>
            <li>Capacity Building and Training</li>
            <li>Public Engagement and Stakeholder Co</li>
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {Card} from '@/components';
import TypingTitle from '@/components/TypingTitle';

export default {
  name: 'About',
  bodyClass: 'about-page',
  components: {
    Card,
    TypingTitle
  }
};
</script>
<style>

</style>